import React, { useEffect } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress } from '@material-ui/core';
import Layout from '../../components/layout';

const La = styled(Layout)``;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Room = () => {
  useEffect(() => {
    window.location.replace(
      `${window.location.origin}/room/${uuidv4()}?openExternalBrowser=1`
    );
  }, []);

  return (
    <La background="white">
      <Container>
        <CircularProgress size={50}></CircularProgress>
      </Container>
    </La>
  );
};

export default Room;
